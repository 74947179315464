import request from '@/network/request.js'
/**
 * 初次获取文件列表信息
 */
export function file_interspace(data) {
	return request({
		url: '/admin/base/file_interspace',
		method: 'post',
		data,
	})
}


/**
 * 初次获取文件列表信息
 */
export function delete_files_interspacereq(data) {
	return request({
		url: '/admin/base/delete_files_interspace',
		method: 'post',
		data,
	})
}