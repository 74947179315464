import request from '@/network/request.js'
/**
 * 获取主视频列表信息
 */
export function lists_mainvideo(data) {
	return request({
		url: '/admin/dramavideo/lists_mainvideo',
		method: 'post',
		data,
	})
}

/**
 * 增加|修改主视频列表信息
 */
export function add_up_mainvideo(data) {
	return request({
		url: '/admin/dramavideo/add_up_mainvideo',
		method: 'post',
		data,
	})
}

/**
 * 删除|批量主列表的视频信息
 */
export function dels_mainvideo(data) {
	return request({
		url: '/admin/dramavideo/dels_mainvideo',
		method: 'post',
		data,
	})
}



